/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { Container, NavbarBrand } from "reactstrap";
import { Link } from "react-router-dom";

export default function Footer() {
  return <footer className="footer" data-background-color="black" style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#2c2c2c', marginTop: '10px' }}>
    <Container style={{ alignItems: 'center', justifyContent: 'space-between', display: 'flex' }}>
      <div className="copyright float-left">
        <a href="/page/TerminosCondiciones">
          <h6 className="info-title texto" style={{ textAlign: 'left', cursor: "pointer", color: 'white' }}>Términos y condiciones</h6>
        </a>
        <a href="/page/PoliticasDatosPersonales">
          <h6 className="info-title texto" style={{ textAlign: 'left', cursor: "pointer", color: 'white' }}>Política de Tratamiento de Datos Personales</h6>
        </a>
        <a href="/page/PoliticasDerechosAutor">
          <h6 className="info-title texto" style={{ textAlign: 'left', cursor: "pointer", color: 'white' }}>Politícas de derechos de autor</h6>
        </a>
        <a href="/page/CertificadoAccesibilidad">
          <h6 className="info-title texto" style={{ textAlign: 'left', cursor: "pointer", color: 'white' }}>Certificado de Accesibilidad</h6>
        </a>
        <a href="/page/PoliticasPrivacidadWeb">
          <h6 className="info-title texto" style={{ textAlign: 'left', cursor: "pointer", color: 'white' }}>Políticas de Privacidad web</h6>
        </a>
        <a href="/page/MapaSitio">
          <h6 className="info-title texto" style={{ textAlign: 'left', cursor: "pointer", color: 'white' }}>Mapa del Sitio</h6>
        </a>
        <a href="/page/home#contacts">
          <h6 className="info-title texto" style={{ textAlign: 'left', cursor: "pointer", color: 'white' }}>Canal de Atencion al Publico</h6>
        </a>
        <h6 className="info-title texto" style={{ textAlign: 'left', cursor: "pointer", color: 'white' }}>Telefono – Conmutador : +57 (604) 2 603 062</h6>
      </div>
      <div className="copyright" id="copyright" style={{ float: 'right' }} >
        <NavbarBrand to="/" tag={Link} id="navbar-brand">
          <img style={{ backgroundColor: 'white', borderRadius:'15px' }} src={require("assets/img/notaria/logoNavbar.png")} alt="Logo de la notaria 14 Medellin" width="160px"></img>
        </NavbarBrand>
      </div>
    </Container>
    <div style={{ backgroundColor: '#474747', width: '100%', height: '40px', display: 'flex', alignItems: 'center', paddingLeft: '30px', color: 'white'}}>
    <a href="" target="_blank" style={{ textDecorationLine: "none" }} rel="noreferrer">
        <img
          alt="Logo de Instagram"
          src={require("assets/img/notaria/instagram.jpg")} style={{ height: '40px', borderRadius:'15px' }}
        ></img>
      </a>
      <a href="" target="_blank" style={{ textDecorationLine: "none" }} rel="noreferrer">
        <img
          alt="Logo de Twitter"
          src={require("assets/img/notaria/twitter.png")} style={{ height: '40px', borderRadius:'15px' }}
        ></img>
      </a>
      <a href="" target="_blank" style={{ textDecorationLine: "none" }} rel="noreferrer">
        <img
          alt="Logo de Facebook"
          src={require("assets/img/notaria/facebook.png")} style={{ height: '40px', borderRadius:'15px' }}
        ></img>
      </a>
      © {new Date().getFullYear()}, Designed by{'\u00A0'}
      <a
        href="https://www.sinfony.com.co/sinfony/"
        target="_blank"
        style={{color: 'white'}}
      >
        SINFONY Todos los derechos reservados.
      </a>
    </div>
  </footer>
}